import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {
    getCondition(state){
      return state.condition
    }
  },
  mutations: {},
  actions: {
    search(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/feeloadsetting/search', {params: params})
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    view(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/feeloadsetting/view', {params: params})
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    edit(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .get('/api/feeloadsetting/edit', {params: params})
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    save(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/feeloadsetting/save', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    state(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .post('/api/feeloadsetting/state', params)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    importExcel(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .post('/api/feeloadsetting/importExcel', params)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
  },
}
