<template>
  <b-card
      class="feeloadsetting-edit-wrapper"
  >
    <!-- form -->
    <b-form id="feeloadsettingForm" class="edit-form mt-2">
      <b-row>
        <!--仓库名称-->
        <b-col md="4">
          <modal-select
              label="仓库名称 *"
              type="input"
              v-on:change="fromChildren($event,['warehouse_name','warehouse_id'])"
              :params="['warehouse_name','warehouse_id']"
              modalName="仓库"
              placeholder="点击选择仓库"
              v-model="feeloadsetting.warehouse_name"
          >
          </modal-select>
        </b-col>
        <!--装卸队-->
        <b-col md="4">
          <modal-select
              label="装卸队 *"
              type="input"
              v-on:change="fromChildren($event,['company_name','company_id'])"
              :params="['company_name','company_id']"
              modalName="装卸队"
              placeholder="点击选择装卸队"
              v-model="feeloadsetting.company_name"
          >
          </modal-select>
        </b-col>
        <!--分类-->
        <b-col md="4">
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="产品分类"
              label-for="category"
              label-size="sm"
              class="mb-1"
          >
            <b-form-input
                id="chooseId"
                size="sm"
                readonly
                placeholder="请选择分类"
                v-b-modal.modal-select-category
                v-model="category_name_value"
            />
          </b-form-group>
        </b-col>
        <!--默认计价-->
        <b-col md="4">
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="默认计价"
              label-for="default_rule"
              label-size="sm"
              class="mb-1 required"
          >
            <v-select
                id="default_rule"
                :options="getCodeOptions('fee_load_setting_type')"
                v-model="feeloadsetting.default_rule"
                :reduce="option => option.value"
                class="select-size-sm"
                placeholder="请选择默认计价"
            />
          </b-form-group>
        </b-col>

        <b-col md="4">
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="元/托"
              label-for="rule1"
              label-size="sm"
              class="mb-1"
          >
            <b-form-input
                id="rule1"
                size="sm"
                type="number"
                v-model="feeloadsetting.rule1"
            />
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="元/件"
              label-for="rule2"
              label-size="sm"
              class="mb-1"
          >
            <b-form-input
                id="rule2"
                size="sm"
                type="number"
                v-model="feeloadsetting.rule2"
            />
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="元/吨"
              label-for="rule3"
              label-size="sm"
              class="mb-1"
          >
            <b-form-input
                id="rule3"
                size="sm"
                type="number"
                v-model="feeloadsetting.rule3"
            />
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="元/方"
              label-for="rule4"
              label-size="sm"
              class="mb-1"
          >
            <b-form-input
                id="rule4"
                size="sm"
                type="number"
                v-model="feeloadsetting.rule4"
            />
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="不结算"
              label-for="rule0"
              label-size="sm"
              class="mb-1"
          >
            <b-form-input
                id="rule0"
                size="sm"
                type="number"
                v-model="feeloadsetting.rule0"
            />
          </b-form-group>
        </b-col>

        <b-col md="4">
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="采购入库单结算"
              label-for="switch0"
              label-size="sm"
              class="mb-1"
          >
            <b-form-checkbox
                name="check-button"
                switch
                inline
                id="switch0"
                v-model="feeloadsetting.switch0"
                value="1"
                unchecked-value="0"
            ></b-form-checkbox>
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="调拨出库单结算"
              label-for="switch1"
              label-size="sm"
              class="mb-1"
          >
            <b-form-checkbox
                name="check-button"
                switch
                inline
                id="switch1"
                v-model="feeloadsetting.switch1"
                value="1"
                unchecked-value="0"
            ></b-form-checkbox>
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="调拨入库单结算"
              label-for="switch2"
              label-size="sm"
              class="mb-1"
          >
            <b-form-checkbox
                name="check-button"
                switch
                inline
                id="switch2"
                v-model="feeloadsetting.switch2"
                value="1"
                unchecked-value="0"
            ></b-form-checkbox>
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="线下销售单结算"
              label-for="switch3"
              label-size="sm"
              class="mb-1"
          >
            <b-form-checkbox
                name="check-button"
                switch
                inline
                id="switch3"
                v-model="feeloadsetting.switch3"
                value="1"
                unchecked-value="0"
            ></b-form-checkbox>
          </b-form-group>
        </b-col>

        <b-col md="12">
          <b-form-group
              label-cols="1"
              label-cols-lg="1"
              label="备注"
              label-for="remark"
              label-size="sm"
              class="mb-1"
          >
            <b-form-textarea
                id="remark"
                size="sm"
                v-model="feeloadsetting.remark"
                rows="3"
                max-rows="6"
            />
          </b-form-group>
        </b-col>
        <b-col md="12">
          <b-form-group
              label-cols="1"
              label-cols-lg="1"
              label="附件上传"
              label-for="attachments"
              label-size="sm"
              :class="'mb-1'"
          >
            <attachment-upload v-if="feeloadsetting.loaded" :theme="'files'"
                               :attachment_id="'attachments'"
                               :id="feeloadsetting.attachments"
                               :object_type="'feeloadsetting'"
                               :object_id="feeloadsetting.load_id"
                               @change="onUploaded"
            />
          </b-form-group>
        </b-col>
        <!--保存-->
        <b-col
            cols="12"
            class="mt-50"
        >
          <b-button
              variant="primary"
              class="mr-1"
              @click="save"
          >
            保存
          </b-button>
          <b-button
              variant="outline-secondary"
              @click="cancel"
          >
            取消
          </b-button>
        </b-col>
      </b-row>
    </b-form>
    <!--/ form -->
    <b-modal
        id="modal-select-category"
        no-close-on-backdrop
        ok-title="确认"
        cancel-title="取消"
        centered
        @ok="onSelectCategory"
        size="lg"
        title="分类选择"
    >
      <category-select
          :categoryOptions="category_options"
          :level="level"
          ref="categorySel">
      </category-select>

    </b-modal>
  </b-card>
</template>

<script>
import {
  BCard, BMedia, BAvatar, BCardText, BForm, BRow, BCol, BFormGroup, BFormInput, BImg, BFormFile, BLink, BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {onUnmounted, ref} from '@vue/composition-api'
import router from "@/router";
import store from "@/store";
import feeloadsettingStore from './feeloadsettingStore'
// Notification
import {useToast} from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {toTime, toDate, getCodeOptions, getCode, getCodeLabel, getCodeColor, isEmpty} from '@core/utils/filter'
import ModalSelect from "@/views/components/modal/ModalSelect";
import AttachmentUpload from "@/views/apps/attachment/AttachmentUpload";
import categorySelect from "@/views/apps/category/category-modal/CategorySelect";
import categoryStore from "@/views/apps/category/categoryStore";

export default {
  components: {
    BCard,
    BMedia,
    BAvatar,
    BCardText,
    BForm,
    BLink,
    BImg,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,
    vSelect,
    useToast,
    ModalSelect,
    AttachmentUpload,
    categorySelect,
  },
  data() {
    return {
      id: ref(0),
      feeloadsetting: ref({}),
      category_name_value: "",
      category_options: [],
      level: 2,
      commonFlag: false,
    }
  },
  setup() {
    const toast = useToast()

    // Register module
    if (!store.hasModule('feeloadsetting')) store.registerModule('feeloadsetting', feeloadsettingStore)
    if (!store.hasModule('category')) store.registerModule('category', categoryStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('feeloadsetting')) store.unregisterModule('feeloadsetting')
      if (store.hasModule('category')) store.unregisterModule('category')
    })

    const edit = function () {
      store.dispatch('feeloadsetting/edit', {id: this.id}).then(res => {
        this.feeloadsetting = res.data.data
        if(this.feeloadsetting.new){
          this.feeloadsetting.switch0 = "1"
          this.feeloadsetting.switch1 = "1"
          this.feeloadsetting.switch2 = "1"
          this.feeloadsetting.switch3 = "1"
        }
        //是否是通用计费
        if (this.feeloadsetting.new == false && this.feeloadsetting.category_three_id == null) {
          this.commonFlag = true
        }
        //编辑
        let ids = [];
        ids.push(this.feeloadsetting.category_id ? this.feeloadsetting.category_id : 0, this.feeloadsetting.category_tow_id ? this.feeloadsetting.category_tow_id : 0, this.feeloadsetting.category_three_id ? this.feeloadsetting.category_three_id : 0);
        if (ids[0]) {
          let content = "";
          let reqParam = ids.join(",")
          store.dispatch('category/searchByIds', {category_ids: reqParam}).then(res => {
            let a = res.data.data;
            for (let i = 0; i < a.length; i++) {
              content += a[i].name;
              content += "/";
              this.category_options.push({"label": a[i].name, "category_id": a[i].category_id});
            }
            this.category_name_value = content;
          });
        }
      })
    }

    const view = function () {
      store.dispatch('feeloadsetting/view', {id: this.id}).then(res => {
        this.feeloadsetting = res.data.data
      })
    }

    const cancel = function () {
      this.$router.go(-1)
    }

    const save = function () {
      if (this.feeloadsetting.warehouse_name == null) {
        toast.error('请选择仓库名称')
        return false
      }
      if (this.feeloadsetting.company_name == null) {
        toast.error('请选择装卸队')
        return false
      }
      if (this.feeloadsetting.default_rule == null) {
        toast.error('请选择默认计价')
        return false
      }
      if (this.feeloadsetting.rule0 < 0 || this.feeloadsetting.rule1 < 0 || this.feeloadsetting.rule2 < 0 || this.feeloadsetting.rule3 < 0 || this.feeloadsetting.rule4 < 0) {
        toast.error('价格不能是负数')
        return false
      }
      // if(isEmpty(this.feeloadsetting.rule0) && isEmpty(this.feeloadsetting.rule1) && isEmpty(this.feeloadsetting.rule2) && isEmpty(this.feeloadsetting.rule3) && isEmpty(this.feeloadsetting.rule4)){
      //   toast.error('价格不能全为空')
      //   return false
      // }
      if (!isEmpty(this.feeloadsetting.category_three_id) && this.commonFlag) {
        toast.error('不能覆盖通用计费')
        return false
      }
      console.log(this.feeloadsetting)
      store.dispatch('feeloadsetting/save', this.feeloadsetting).then(res => {
        if (res.data.code == 0) {
          toast.success('数据已保存!')
          this.$router.push({name: 'apps-feeloadsetting-list'});
        } else {
          toast.error(res.data.data)
        }
      })
    }

    const onUploaded = function (id, attachment, result) {
      this.feeloadsetting[id] = result
    }

    const fromChildren = function (params, modal) {
      for (let i = 0; i < modal.length; i++) {
        this.feeloadsetting[modal[i]] = params == null ? null : params[modal[i]]
      }
      this.$forceUpdate()
    }

    const onSelectCategory = function () { //赋值回显
      this.feeloadsetting.category_id = isEmpty(this.$refs.categorySel.categoryId) ? null : this.$refs.categorySel.categoryId;
      this.feeloadsetting.category_tow_id = isEmpty(this.$refs.categorySel.categoryTowId) ? null : this.$refs.categorySel.categoryTowId;
      this.feeloadsetting.category_three_id = isEmpty(this.$refs.categorySel.categoryThreeId) ? null : this.$refs.categorySel.categoryThreeId;
      this.category_options = [];
      this.category_options.push(
          {
            "label": this.$refs.categorySel.categoryName,
            "category_id": this.$refs.categorySel.categoryId,
          },
          {
            "label": this.$refs.categorySel.categoryTowName,
            "category_id": this.$refs.categorySel.categoryTowId,
          },
          {
            "label": this.$refs.categorySel.categoryThreeName,
            "category_id": this.$refs.categorySel.categoryThreeId,
          },
      );
      let new_content = "";
      if(!isEmpty(this.$refs.categorySel.categoryTowName)){
        new_content += this.$refs.categorySel.categoryName + "/";
        new_content += this.$refs.categorySel.categoryTowName + "/";
        new_content += this.$refs.categorySel.categoryThreeName + "/";
      }
      this.category_name_value = new_content;
    }

    return {
      edit,
      view,
      cancel,
      save,
      onSelectCategory,

      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,
      onUploaded,
      fromChildren,
    }
  },
  created() {
    this.id = this.$route.query.id || 0;
    this.edit()
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
